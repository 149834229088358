<template>
  <div id="DemandeDetail">
    <v-container>
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <v-card class="pa-3" outlined tile>
        <h3 style="margin-bottom: 30px">Informations du client</h3>

        <v-row style="margin-left: 10px">
          <v-col cols="12" md="4" sm="12">
            <span class="title">USER ID :</span>

            <span
              @click="redirectToUserDetails(demandeDetail.userId)"
              style="cursor: pointer"
            >
              {{ demandeDetail.userId }}</span
            >
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> Nom :</span>{{ demandeDetail.nom }}
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> Prénom :</span>{{ demandeDetail.prenom }}
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col cols="12" md="4" sm="12">
            <span class="title">Carte d'identité recto :</span>
            <a :href="demandeDetail.cinRecto" target="_blank">
              <v-icon> mdi-file-document-outline</v-icon></a
            >
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> Carte d'identité verso :</span>
            <a :href="demandeDetail.cinVerso" target="_blank">
              <v-icon> mdi-file-document-outline</v-icon></a
            >
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-3" outlined tile>
        <h3 style="margin-bottom: 30px">Informations de l'entreprise</h3>
        <v-row style="margin-left: 10px">
          <v-col cols="12" md="4" sm="12">
            <span class="title">Activité de l'entreprise :</span>
            {{ demandeDetail.activite }}
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> Adresse de l'entreprise :</span
            >{{ demandeDetail.adresse }}
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> forme juridique :</span
            >{{ demandeDetail.legalStatus }}
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col cols="12" md="4" sm="12">
            <span class="title">Numéro fiscale de l'entreprise :</span>
            {{ demandeDetail.taxNumber }}
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> site web :</span>{{ demandeDetail.webSite }}
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <span class="title"> RNA :</span>
            <a :href="demandeDetail.Rna" target="_blank">
              <v-icon> mdi-file-document-outline</v-icon></a
            >
          </v-col>
        </v-row>
        <v-row style="margin-left: 10px">
          <v-col cols="12" md="4" sm="12">
            <span class="title"> Patente :</span>
            <a :href="demandeDetail.patente" target="_blank">
              <v-icon> mdi-file-document-outline</v-icon></a
            >
          </v-col>
        </v-row>
        <div
          class="d-flex flex-row-reverse"
          style="margin-top: 60px; margin-bottom: 30px"
        >
          <v-btn
            color="primary"
            dark
            style="margin-right: 30px; width: 150px"
            @click="dialogValide = true"
          >
            Valider la demande
          </v-btn>
          <v-btn
            color="red"
            dark
            style="margin-right: 30px; width: 150px"
            @click="dialogInvalide = true"
          >
            INValider la demande
          </v-btn>
        </div>
      </v-card>
    </v-container>
    <!-- Boite de dialog confirmation validation de la demande -->
    <v-dialog v-model="dialogValide" max-width="390">
      <v-card>
        <v-card-title class="text-h5">
          Etes vous sur de vouloir VALIDER la demande ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialogValide = false">
            Annuler
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            :loading="loading"
            @click="updateStatusValide()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Boite de dialogue confirmation d'invalidation de la demande -->
    <v-dialog v-model="dialogInvalide" max-width="390">
      <v-card>
        <v-card-title class="text-h5">
          Etes vous sur de vouloir INVALIDER la demande ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialogInvalide = false">
            Annuler
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            :loading="loading"
            @click="updateStatusInvalide()"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
export default {
  name: "DemandeDetail",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Liste des demandes de transformation de comptes en compte PRO",
          disabled: false,
          to: "/MercaAdmin/ListeDemande",
        },
      ],
      demandeDetail: [],
      dialogValide: false,
      dialogInvalide: false,
      loading: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    var demandeDetail = null;

    if (to.params.item != null) {
      localStorage.setItem(
        "detailsDemandeComptePro",
        JSON.stringify(to.params.item)
      );
    }

    demandeDetail = JSON.parse(localStorage.getItem("detailsDemandeComptePro"));

    console.log(demandeDetail);

    next((vm) => {
      vm.demandeDetail = demandeDetail;
    });
  },
  methods: {
    redirectToUserDetails(user) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: user,
        },
      });
    },
    updateStatusValide() {
      this.loading = true;
      db.collection(NodesName.USER_PRO_VALIDATION)
        .doc(this.demandeDetail.demande_id)
        .update({ status: "VALIDE" })
        .then(() => {
          db.collection(NodesName.USERS_DETAILS)
            .doc(this.demandeDetail.userId)
            .update({ professional: true })
            .then(() => {
              this.dialogValide = false;
              this.loading = false;
              this.$router.push({
                name: "ListeDemande",
              });
            });
        });
    },
    updateStatusInvalide() {
      this.loading = true;
      db.collection(NodesName.USER_PRO_VALIDATION)
        .doc(this.demandeDetail.demande_id)
        .update({ status: "INVALIDE" })
        .then(() => {
          this.loading = false;
          this.dialogInvalide = false;
          this.$router.push({
            name: "ListeDemande",
          });
        });
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 15px !important;
  font-weight: bold;
  margin-right: 10px;
}
</style>
